import React, { Fragment, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { setAlert } from '../../Redux/actions/alert';
import { confirmarInvitacion } from '../../Redux/actions/invitaciones';

const styles = {
    contenedor_inicial: {
        marginTop: '20px'
    },
    fh5co_cover: {
    	backgroundImage: "url('/assets/images/cover_bg_1.jpg')"
    },
    check_zone: {
        display: 'flex',
        alignItems: 'center'
    }
}

const Inicio = () => {

	const dispatch = useDispatch();

	//Cuando y donde
	const [direcciones, setDirecciones ] = useState({
        ceremonia: 'https://maps.app.goo.gl/Sxt4obMbbo8SCw2r9',
        recepcion: 'https://maps.app.goo.gl/aYcPNWFCNW2S3Tv39'
    });

	//Confirmacion
	const [confirmacionModal, setConfirmacionModal] = useState(false);

	const [confirmarData, setConfirmarData ] = useState({
		id_compra: process.env.REACT_APP_ID_COMPRA,
        codigo: '',
        adultos: [],
        ninos: []
    });

	//Cuando y donde
	const onOpenAddress = (direccion) => {
        window.open(direccion, '_blank');
    }

	//Confirmacion
    const onChangeCodigo = (e) => {
        setConfirmarData({
            ...confirmarData, [e.target.name]: e.target.value.trim()
        });
    }

	const handleAbrirConfirmar = async () => {

    	// hacemos el request
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({ 'codigo': confirmarData.codigo, 'id_compra': process.env.REACT_APP_ID_COMPRA });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitaciones/boletos`, body, config);

			console.log(res.data.invitacion);

            if (res.data.success) {
				setConfirmarData({
					...confirmarData,
				    adultos: res.data.invitacion.adultos,
				    ninos: res.data.invitacion.ninos
				});

                setConfirmacionModal(true);
            } else {
                setAlert('Algo salió mal, inténtalo más tarde', 'danger')
            }
        } catch (error) {
			console.log(error);
            if(error.response){
	            if (error.response.data.errors) {
	                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
	            }else{
	                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
	            }
	        }else{
	            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
	        }
        }
    }

	const listadoAdultos = (boletos) => {
		if(boletos.length > 0){
			const items = [];

			items.push(
    	 		<div className="col-12" key={0}>
                    <p><b>Adultos</b></p>
                </div>
            );

			for (var i = 0; i < boletos.length; i++) {
				let indicador = i + 1;

        	 	items.push(
        	 		<div className="col-6 mb-2" style={styles.check_zone} key={indicador}>
                        <div className="form-check">
                            <input type="checkbox" id={'check-adulto-' + indicador} onChange={e => handleCheckboxAdulto(e)} checked={boletos[i].status}></input>
                            <label className="form-check-label" htmlFor={'check-adulto-' + indicador}>{boletos[i].nombre ? boletos[i].nombre : 'Invitado(a)'}</label>
                        </div>
                    </div>
                );
            }

        	return (
	        	<div className="row">
		            {items}
	        	</div>
        	);
        }else{
        	return (
                <div></div>
        	);
        }
    }

    const listadoNinos = (boletos) => {
		if(boletos.length > 0){
			const items = [];

			items.push(
    	 		<div className="col-12 mt-4" key={0}>
                    <p><b>Niños</b></p>
                </div>
            );

			for (var i = 0; i < boletos.length; i++) {
				let indicador = i + 1;

        	 	items.push(
        	 		<div className="col-6 mb-2" style={styles.check_zone} key={indicador}>
                        <div className="form-check">
                            <input type="checkbox" id={'check-nino-' + indicador} onChange={e => handleCheckboxNino(e)} checked={boletos[i].status}></input>
                            <label className="form-check-label" htmlFor={'check-nino-' + indicador}>{boletos[i].nombre ? boletos[i].nombre : 'Invitado(a)'}</label>
                        </div>
                    </div>
                );
            }

        	return (
	        	<div className="row">
		            {items}
	        	</div>
        	);
        }else{
        	return (
                <div></div>
        	);
        }
    }

    const handleCheckboxAdulto = ({ target }) => {
    	let adultos_actual = confirmarData.adultos;

    	let posicion_adulto = parseInt(target.id.split('-')[2]) - 1;

		adultos_actual[posicion_adulto].status = target.checked;

        setConfirmarData({
			...confirmarData, 
			adultos: adultos_actual
		});
    }

    const handleCheckboxNino = ({ target }) => {
    	let ninos_actual = confirmarData.ninos;

    	let posicion_nino = parseInt(target.id.split('-')[2]) - 1;

		ninos_actual[posicion_nino].status = target.checked;

        setConfirmarData({
			...confirmarData, 
			ninos: ninos_actual
		});
    }

    const handleGuardarConfirmar = async () => {
        await dispatch(confirmarInvitacion(confirmarData));
        await handleCerrarConfirmar();
    }

	const handleCerrarConfirmar = async () => {
        await setConfirmacionModal(false);
        await setConfirmarData({
        	...confirmarData,
            codigo: '',
            adultos: [],
	        ninos: []
        });
    }
    
    return (
    	<Fragment>

    		{/* Modal de confirmación */}
            <Modal show={confirmacionModal} centered onHide={() => { handleCerrarConfirmar() }}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar asistencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Selecciona los boletos a confirmar:</p>
                    {
	                    listadoAdultos(confirmarData.adultos)
	                }
	                {
	                    listadoNinos(confirmarData.ninos)
	                }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleGuardarConfirmar}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>

			<div id="fh5co-couple" className="fh5co-section-gray">
				<div className="container" style={styles.contenedor_inicial}>
					<div className="row row-bottom-padded-md animate-box">
						<div className="col-md-6 offset-md-3 text-center d-flex">
							<div className="col-md-5 col-sm-5 col-5 nopadding">
								<h1>Mónica <br/>Landeros</h1>
							</div>
							<div className="col-md-2 col-sm-2 col-2 nopadding"><h2 className="amp-center"><i className="icon-heart"></i></h2></div>
							<div className="col-md-5 col-sm-5 col-5 nopadding">
								<h1>Daniel <br/>Ortiz</h1>
							</div>
						</div>
					</div>
					<div className="row animate-box">
						<div className="col-md-8 offset-md-2">
							<div className="col-md-12 text-center heading-section">
								<h2>Se van a casar</h2>
								<p><strong>el 7 de Diciembre de 2024 &mdash; Aguascalientes, Ags</strong></p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 mb-3 animate-box">
							<div className="row animate-box">
								<div className="col-md-8 offset-md-2 text-center heading-section mb-0">
									<h2>Padres de la novia</h2>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 text-center animate-box">
									<div className="groom-men">
										<h3>María Guadalupe Sosa Zermeño</h3>
									</div>
								</div>
								<div className="col-md-6 text-center animate-box">
									<div className="groom-men">
										<h3>Fernando Landeros Cardona</h3>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 mb-3 animate-box">
							<div className="row animate-box">
								<div className="col-md-8 offset-md-2 text-center heading-section mb-0">
									<h2>Padres del novio</h2>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 text-center animate-box">
									<div className="groom-men">
										<h3>Claudia Lorena Guadian Estévez</h3>
									</div>
								</div>
								<div className="col-md-6 text-center animate-box">
									<div className="groom-men">
										<h3>José Ortiz Hernández</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="fh5co-countdown">
				<div className="row">
					<div className="col-md-8 offset-md-2 text-center animate-box">
						<p className="countdown">
							<span id="days"></span>
							<span id="hours"></span>
							<span id="minutes"></span>
							<span id="seconds"></span>
						</p>
					</div>
				</div>
			</div>

			<div id="fh5co-when-where" className="fh5co-section-gray">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<h2>¿Cuando y donde?</h2>
						</div>
					</div>
					<div className="row row-bottom-padded-md">
						<div className="col-md-6 text-center animate-box">
							<div className="wedding-events">
								<div className="ceremony-bg" style={{ backgroundImage: "url('/assets/images/wed-ceremony.jpg')", cursor: 'pointer' }} onClick={() => onOpenAddress(direcciones.ceremonia)}></div>
								<div className="desc">
									<h3 style={styles.btn_direccion} onClick={() => onOpenAddress(direcciones.ceremonia)}>Ceremonia religiosa</h3>
									<p><strong>Sábado 7 de Diciembre de 2024, 7:00 PM</strong></p>
									<p>Esperamos contar con su presencia en la ceremonia religiosa que se llevara a cabo en la Catedral de Aguascalientes en punto de las 7:00 pm.</p>
									<button type="button" className="btn btn-primary btn-block" onClick={() => onOpenAddress(direcciones.ceremonia)}>Ver ubicación</button>
								</div>
							</div>
						</div>
						<div className="col-md-6 text-center animate-box">
							<div className="wedding-events">
								<div className="ceremony-bg" style={{ backgroundImage: "url('/assets/images/wed-party.jpg')", cursor: 'pointer' }} onClick={() => onOpenAddress(direcciones.recepcion)}></div>
								<div className="desc">
									<h3 style={styles.btn_direccion} onClick={() => onOpenAddress(direcciones.recepcion)}>Recepción</h3>
									<p><strong>Sabado 7 de Diciembre de 2024, 8:30 PM</strong></p>
									<p>Una vez concluida la ceremonia religiosa, nos complacerá que nos acompañe en la recepción, la cual se llevara a cabo en la Hacienda Vista Hermosa a partir de las 8:30 pm.</p>
									<button type="button" className="btn btn-primary btn-block" onClick={() => onOpenAddress(direcciones.recepcion)}>Ver ubicación</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="fh5co-when-where">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<h2>Mesa de regalos</h2>
						</div>
					</div>
					<div className="row">
						<div className="offset-md-2 col-md-4 text-center animate-box">
							<div className="wedding-events mb-3">
								<div className="gallery animate-box mb-0">
									<a className="gallery-img" href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/51464654" target="_blank" rel="noreferrer"><img src="/assets/images/liverpool.png" className="img-fluid" alt="" /></a>
								</div>
								<div className="cont_codigo">
									<strong>51464654</strong>
								</div>
							</div>
						</div>
						<div className="col-md-4 text-center animate-box">
							<div className="wedding-events mb-3">
								<div className="gallery animate-box mb-0">
									<a className="gallery-img" href="https://www.amazon.com.mx/wedding/share/MonicaDaniel7dic2024" target="_blank" rel="noreferrer"><img src="/assets/images/amazon.png" className="img-fluid" alt="" /></a>
								</div>
								<div className="cont_codigo">
									<strong>Monica & Daniel</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="fh5co-when-where" className="fh5co-section-gray">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<h2>Itinerario</h2>
						</div>
					</div>
					<div className="row">
						<div className="offset-md-3 col-md-6 mb-3 animate-box">
							<div className="itinerario">
								<div className="evento text-center">
									<h2>Ceremonia</h2>
									<p className="hora">19:00 - Catedral de Aguascalientes</p>
								</div>
							</div>
							<div className="itinerario">
								<div className="evento text-center">
									<h2>Recepción</h2>
									<p className="hora">20:30 - Hacienda Vista Hermosa</p>
								</div>
							</div>
							<div className="itinerario">
								<div className="evento text-center">
									<h2>Entrada de novios</h2>
									<p className="hora">21:00 - Hacienda Vista Hermosa</p>
								</div>
							</div>
							<div className="itinerario">
								<div className="evento text-center">
									<h2>Cena</h2>
									<p className="hora">21:20 - Hacienda Vista Hermosa</p>
								</div>
							</div>
							<div className="itinerario">
								<div className="evento text-center">
									<h2>Primer baile</h2>
									<p className="hora">22:00 - Hacienda Vista Hermosa</p>
								</div>
							</div>
							<div className="itinerario">
								<div className="evento text-center">
									<h2>Abrimos pista</h2>
									<p className="hora">22:05 - Hacienda Vista Hermosa</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="fh5co-when-where">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<h2>Sugerencias de hospedaje</h2>
						</div>
					</div>

					<div className="row">
						<div className="offset-md-3 col-md-6 text-center animate-box">
							<div className="wedding-events mb-3">
								<div className="gallery animate-box mb-0">
									<img src="/assets/images/marriott.png" className="img-fluid" alt="" />
								</div>
								<div className="cont_codigo">
									<strong><a className="gallery-img text-white" href="mailto:hma.reservaciones@grupodiestra.con">hma.reservaciones@grupodiestra.com</a></strong><br />
									<strong><a className="gallery-img text-white" href="mailto:hma.reserv2@grupodistra.com">hma.reserv2@grupodistra.com</a></strong><br />

									<strong>449 139 40 60 ext. 2110 y 2111</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="fh5co-when-where" className="fh5co-section-gray">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<h2>Recomendaciones</h2>
						</div>
					</div>
					<div className="row">
						<div className="offset-md-3 col-md-6 mb-3 animate-box">
							<div className="itinerario">
								<div className="evento text-center">
									<h2>Código de vestimenta</h2>
									<p className="hora">¡Dejemos los tonos blancos y shedron para uso exclusivo de los novios!</p>
								</div>
							</div>
							<div className="itinerario">
								<div className="evento text-center">
									<h2>Confirmar asistencia</h2>
									<p className="hora">Les agradeceriamos si pudieran confirmar su asistencia antes del 7 de noviembre dando click en el botón que se encuentra al final de la página.</p>
								</div>
							</div>
							<div className="itinerario">
								<div className="evento text-center">
									<h2>Puntualidad</h2>
									<p className="hora">Les pedimos que intenten llegar a tiempo tanto a la ceremonia como a la recepción. Nos encantaría comenzar puntualmente para poder disfrutar al máximo de cada momento juntos.</p>
								</div>
							</div>
							<div className="itinerario">
								<div className="evento text-center">
									<h2>Bebidas embriagantes</h2>
									<p className="hora">Evita el abuso de sustancias embriagantes y conductas fuera de lugar.</p>
								</div>
							</div>
							<div className="itinerario">
								<div className="evento text-center">
									<h2>Lugares</h2>
									<p className="hora">Favor de respetar lugares asignados por las hostess.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="fh5co-gallery">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<h2>Nuestra galería</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/1.jpg"><img src="/assets/images/galeria/1.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/2.jpg"><img src="/assets/images/galeria/2.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/3.jpg"><img src="/assets/images/galeria/3.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/4.jpg"><img src="/assets/images/galeria/4.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/5.jpg"><img src="/assets/images/galeria/5.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/6.jpg"><img src="/assets/images/galeria/6.jpg" className="img-fluid" alt="" /></a>
							</div>
						</div>
						<div className="col-md-4">
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/13.png"><img src="/assets/images/galeria/13.png" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/14.png"><img src="/assets/images/galeria/14.png" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/15.png"><img src="/assets/images/galeria/15.png" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/16.png"><img src="/assets/images/galeria/16.png" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/17.png"><img src="/assets/images/galeria/17.png" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/18.png"><img src="/assets/images/galeria/18.png" className="img-fluid" alt="" /></a>
							</div>
						</div>
						<div className="col-md-4">
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/7.jpg"><img src="/assets/images/galeria/7.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/8.jpg"><img src="/assets/images/galeria/8.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/9.jpg"><img src="/assets/images/galeria/9.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/10.jpg"><img src="/assets/images/galeria/10.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/11.png"><img src="/assets/images/galeria/11.png" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/12.png"><img src="/assets/images/galeria/12.png" className="img-fluid" alt="" /></a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="fh5co-started" className="duidui text-center video-container" data-stellar-background-ratio="0.5">
				<div className="overlay"></div>
				<video autoPlay muted loop playsInline >
					<source src="/assets/images/fondo.mp4" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
				<div className="container">
					<div className="row animate-box">
						<div className="col-md-8 offset-md-2 text-center heading-section">
							<h2 className="color_dorado_claro">¿Deseas confirmar tu asistencia?</h2>
							<p className="color_dorado_claro">Da click en el siguiente botón.</p>
						</div>
					</div>
					<div className="row animate-box">
						<div className="col-md-10 offset-md-1">
							<div className="offset-md-3 col-md-6">
								<a className="btn btn-primary btn-block" href="https://api.whatsapp.com/send?phone=524495828273" target="_blank">Confirmar</a>
							</div>
						</div>
					</div>
				</div>
			</div>
	    </Fragment>
    )
}

export default Inicio;